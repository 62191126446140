import {
    API_ERROR,
    API_SUCCESS,
    API_REMOVE
  } from "../../redux/ActionType";
  import {
    apiCall
  } from "../../services/apiCall";
  
  
  export function addCustomer(data) {
    return (dispatch) => {
      dispatch({
        type: API_REMOVE,
      });
      return new Promise(async (resolve, reject) => {
        await apiCall('POST', "user/addcustomers", data).then(data => {
          dispatch({
            type: API_SUCCESS,
            data
          });
          setTimeout(() => {window.location.reload();dispatch({
            type: API_REMOVE,
          })}, 2000)
          resolve();
        }).catch(error => {
          dispatch({
            type: API_ERROR,
            error
          });
          reject()
        });
      })
    }
  }
  export function CustomerLogo(data) {
    return (dispatch) => {
      return new Promise(async (resolve, reject) => {
        await apiCall('POST', "user/upload", data).then(data => {
          dispatch({
            type: API_SUCCESS,
            data
          });
          setTimeout(() => dispatch({
            type: API_REMOVE,
          }), 5000)
          resolve(data);
        }).catch(error => {
          dispatch({
            type: API_ERROR,
            error
          });
          setTimeout(() => dispatch({
            type: API_REMOVE,
          }), 5000)
          reject(error)
        });
      })
    }
  }
  export function getAllCustomer() {
    return (dispatch) => {
      return new Promise(async (resolve, reject) => {
        await apiCall('get', "user/listcustomers").then(data => {
          // dispatch({
          //   type: API_SUCCESS,
          //   data
          // });
          // setTimeout(() => dispatch({
          //   type: API_REMOVE,
          // }), 5000)
          resolve(data);
        }).catch(error => {
          dispatch({
            type: API_ERROR,
            error
          });
          setTimeout(() => dispatch({
            type: API_REMOVE,
          }), 5000)
          reject(error)
        });
      })
    }
  }

  export function updateCustomer(data) {
    return (dispatch) => {
      return new Promise(async (resolve, reject) => {
        await apiCall('patch', "user/updatecustomer/"+`${data._id}`,Object.entries(data).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})).then(data => {
          dispatch({
            type: API_SUCCESS,
            data
          });
          setTimeout(() => {window.location.reload();dispatch({
            type: API_REMOVE,
          })}, 5000)
          resolve(data);
        }).catch(error => {
          dispatch({
            type: API_ERROR,
            error
          });
          setTimeout(() => dispatch({
            type: API_REMOVE,
          }), 5000)
          reject(error)
        });
      })
    }
  }