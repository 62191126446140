import { EgretLoadable } from "egret";

const PurchaseInvoiceList = EgretLoadable({
  loader: () => import("./PurchaseInvoice")
});

const PurchaseInvoiceAdd = EgretLoadable({
  loader: () => import("./PurchaseInvoiceDetails")
});

const Payment = EgretLoadable({
  loader: () => import("./payments/Pay")
});

const PaymentView = EgretLoadable({
  loader: () => import("./payments/ListPayment")
});
const ReportPurchase = EgretLoadable({
  loader: () => import("./Reports/PurchaseReport")
});
const PurchaseInvoiceRoutes = [
  {
    path: "/purchase",
    exact: true,
    component: PurchaseInvoiceList
  },
  {
    path: "/purchase/payment/:id",
    exact: true,
    component: Payment
  },
  {
    path: "/purchase/view/payment",
    exact: true,
    component: PaymentView
  },
  {
    path: "/purchase/:id",
    exact:true,
    component: PurchaseInvoiceAdd
  },
  {
    path: "/purchase/view/:id",
    component: PurchaseInvoiceAdd
  },
  {
    path: "/purchase-summary",
    exact: true,
    component: ReportPurchase
  },
//   {
//     path: "/invoice/:id",
//     component: InvoiceDetails
//   },
//   {
//     path: "/invoice/edit/:id",
//     component: InvoiceList
//   }
];

export default PurchaseInvoiceRoutes;
