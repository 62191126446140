import axios from "axios";
import localStorageService from "./localStorageService";

class JwtAuthService {

  user = {
    // userId: "1",
    // role: 'ADMIN',
    // displayName: "Wastson Joyce",
    // email: "watsonjoyce@gsmail.com",
    age: 25,
    isAuthenticated:true,
    // token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh"
  }

  loginWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      return axios['POST'.toLowerCase()](
        `https://api.taxary.net/api/user/login`,
        {
          "email":email,
          "password":password
      }
      ).then(data => {
        if (data.data.data.business_name) {
          this.user.displayName = data.data.data.business_name;
        }if(data.data.data.logo){          
          this.user.photoURL = data.data.data.logo
        }        
        this.user.userId = data.data.data._id;
        this.user.role = data.data.data.role;
        this.user.token = data.data.data.token;
        this.user.customer = data.data.data.customer;
        this.user.state = data.data.data.state;
        this.user.address_1 = data.data.data.address_1;
        this.user.country = data.data.data.country;
        this.user.account_holder_name = data.data.data.account_holder_name;
        this.user.account_number = data.data.data.account_number;
        this.user.ifsc = data.data.data.ifsc;
      this.setSession(data.data.data.token);
      this.setUser(this.user);
      return resolve({...data.data,user:this.user});
    }).catch((err) => {
      if (typeof err.response === "undefined"){
        return reject({message:err.message});
      }
      else{
        return reject(err.response.data);
      }
    })}); 
  };

  loginWithToken = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if(localStorage.jwt_token)
        resolve(this.user);
        else
        reject();
      }, 100);
    }).then(data => {
      this.setSession(localStorage.jwt_token);
      return data;
    }).catch(error=>{});
  };



  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  setSession = token => {
    if (token) {
      localStorage.setItem("jwt_token", token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      localStorage.removeItem("jwt_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };
  setUser = (user) => {
    localStorageService.setItem("auth_user", user);
  }
  removeUser = () => {
    localStorage.removeItem("auth_user");
  }
}

export default new JwtAuthService();
