import { EgretLoadable } from "egret";

const ListStocks = EgretLoadable({
  loader: () => import("./ListStocks")
});
const ReportStocks = EgretLoadable({
  loader: () => import("./Reports/StockReport")
});


const stockRoutes = [
  {
    path: "/stocks",
    exact: true,
    component: ListStocks
  },
  {
    path: "/stock-summary",
    exact: true,
    component: ReportStocks
  },
];

export default stockRoutes;
