  import {
Tour
  } from "../../EgretLayout/SharedCompoents/Footer";
  
  const initialState = false;
  
  const TourReducer = function(state = initialState, action) {
    switch (action.type) {
      case Tour:{
       return action.data
      }
      default: {
        return state;
      }
    }
  };
  
  export default TourReducer;
  