import { EgretLoadable } from "egret";

const SalesInvoiceList = EgretLoadable({
  loader: () => import("./SalesInvoice")
});

const SalesInvoiceAdd = EgretLoadable({
  loader: () => import("./SalesInvoiceDetails")
});

const Payment = EgretLoadable({
  loader: () => import("./payments/Pay")
});

const PaymentView = EgretLoadable({
  loader: () => import("./payments/ListPayment")
});

const ReportSales = EgretLoadable({
  loader: () => import("./Reports/SalesReport")
});
const SalesInvoiceRoutes = [
  {
    path: "/sales",
    exact: true,
    component: SalesInvoiceList
  },
  {
    path: "/sales/payment/:id",
    exact: true,
    component: Payment
  },
  {
    path: "/sales/view/payment",
    exact: true,
    component: PaymentView
  },
  {
    path: "/sales/:id",
    exact:true,
    component: SalesInvoiceAdd
  },
  {
    path: "/sales/view/:id/:type",
    component: SalesInvoiceAdd
  },
  {
    path: "/sales-summary",
    exact: true,
    component: ReportSales
  },
//   {
//     path: "/invoice/:id",
//     component: InvoiceDetails
//   },
//   {
//     path: "/invoice/edit/:id",
//     component: InvoiceList
//   }
];

export default SalesInvoiceRoutes;
