import { EgretLoadable } from "egret";
import { authRoles } from "../../auth/authRoles";


const Main_Dashboard = EgretLoadable({
  loader: () => import("./main_dashboard")
});
const dashboardRoutes = [

  {
    path: "/dashboard/",
    component: Main_Dashboard,
    auth: authRoles.admin
  }
];

export default dashboardRoutes;
