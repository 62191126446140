import { EgretLoadable } from "egret";

const PartiesList = EgretLoadable({
  loader: () => import("./ListParties")
});

const PartiesAdd = EgretLoadable({
  loader: () => import("./Add_Parties")
});

const partiesRoutes = [
  {
    path: "/parties",
    exact: true,
    component: PartiesList
  },
  {
      path: "/parties/add",
      exact: true,
      component: PartiesAdd
  },
//   {
//     path: "/invoice/:id",
//     component: InvoiceDetails
//   },
//   {
//     path: "/invoice/edit/:id",
//     component: InvoiceList
//   }
];

export default partiesRoutes;
