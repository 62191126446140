import { EgretLoadable } from "egret";

const QuotationList = EgretLoadable({
  loader: () => import("./Quotation")
});

const QuotationAdd = EgretLoadable({
  loader: () => import("./QuotationDetails")
});

const QuotationRoutes = [
  {
    path: "/quotation",
    exact: true,
    component: QuotationList
  },
  {
    path: "/quotation/:id",
    exact:true,
    component: QuotationAdd
  },
  {
    path: "/quotation/view/:id",
    component: QuotationAdd
  },
//   {
//     path: "/invoice/:id",
//     component: InvoiceDetails
//   },
//   {
//     path: "/invoice/edit/:id",
//     component: InvoiceList
//   }
];

export default QuotationRoutes;
