import {
    API_ERROR,API_SUCCESS,API_REMOVE
      } from "../ActionType";
      
      const initialState = {};
      
      const TourReducer = function(state = initialState, action) {
        switch (action.type) {
            case API_ERROR:{
            return {...action.error.data,message:action.message}
               }
          case API_SUCCESS:{
            return {...action.data,message:action.message}
          }
          case API_REMOVE:{
            return {}
          }
          default: {
            return state;
          }
        }
      };
      
      export default TourReducer;
      