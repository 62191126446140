import { EgretLoadable } from "egret";

const CustomerList = EgretLoadable({
  loader: () => import("./ListCustomer")
});

const CustomerAdd = EgretLoadable({
  loader: () => import("./Add_customer")
});

const customerRoutes = [
  {
    path: "/customers",
    exact: true,
    component: CustomerList
  },
  {
      path: "/customer/add",
      exact: true,
      component: CustomerAdd
  },
//   {
//     path: "/invoice/:id",
//     component: InvoiceDetails
//   },
//   {
//     path: "/invoice/edit/:id",
//     component: InvoiceList
//   }
];

export default customerRoutes;
