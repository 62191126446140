import { EgretLoadable } from "egret";

const ProductList = EgretLoadable({
  loader: () => import("./ListProducts")
});

const ProductAdd = EgretLoadable({
  loader: () => import("./Add_Products")
});

const productRoutes = [
  {
    path: "/products",
    exact: true,
    component: ProductList
  },
  {
      path: "/product/add",
      exact: true,
      component: ProductAdd
  },
//   {
//     path: "/invoice/:id",
//     component: InvoiceDetails
//   },
//   {
//     path: "/invoice/edit/:id",
//     component: InvoiceList
//   }
];

export default productRoutes;
