import React from "react";
import { withStyles, MuiThemeProvider, Button,Grid } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";

export const Tour=false;

const Footer = ({ theme, settings,dispatch,Tours,location }) => {
  function  tour() {
    const tour=['/product/add','/Parties/add'];
    const tour_present=tour.find(url=>url===location.pathname?true:false);
    return tour_present?tour_present:false;
    
  }
  const footerTheme = settings.themes[settings.footer.theme] || theme;
  const [state,setState] = React.useState(true);
  return (
    <MuiThemeProvider theme={footerTheme}>
      <Helmet>
        <style>
          {`
              .footer {
                position:relative;
                bottom:0;
                width:100%;
                background: ${footerTheme.palette.primary.main};
                color: ${footerTheme.palette.primary.contrastText};
              }
            `}
        </style>
      </Helmet>
      <div className="footer flex flex-middle" style={{padding:"5px"}}>
        <div className="flex flex-middle container px-sm-30 w-100">
<Grid container >
<Grid item xs={3}>
            {tour()?<Button onClick={()=>{
  dispatch({
      type: Tour,
      data: !Tours
    });}} variant="contained" color="secondary">
              Show Tour
            </Button>:false}
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={5}>
          <span className="m-auto"></span>
          <p className="m-0">
            Design and Developed by <a href="https://pheonixsolutions.com/">Pheonix Solutions</a>
          </p>
          </Grid>
          </Grid>
        </div>
      </div>
    </MuiThemeProvider>
  );
};

Footer.propTypes = {
  settings: PropTypes.object.isRequired
};

const mapStateToProps = state => {return ({
  settings: state.layout.settings,
  Tours:state.Tour,
})};

export default withStyles({}, { withTheme: true })(withRouter(
  connect(
    mapStateToProps
  )(Footer))
);
