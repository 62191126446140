import React, { Component } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  CircularProgress,
  withStyles,
  Input,
  Select,
  Button
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Loading from "../../../egret/components/EgretLoadable/Loading.js";
import { Image } from "@material-ui/icons";
import {
  API_REMOVE
} from "../../redux/ActionType";
import Alert from '@material-ui/lab/Alert';
import Rlert from '../utilities/SectionNotifications';
import { addCustomer,CustomerLogo } from "../Customer/CustomerService";
import { SimpleCard,Breadcrumb,EgretProgressBar } from "egret";
import { Fab, Icon, MenuItem} from "@material-ui/core";
import clsx from 'clsx';
import countries from '../Customer/data.json';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { connect } from "react-redux";

const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    marginLeft:"-50px",
    marginTop:"5px"
  },
  buttonProg: {
    position: "absolute",
    marginTop:"5px",
    marginLeft:"-60px",
  },
});

class SignUp extends Component {
  constructor(props){
    super(props);
    this.props.dispatch({
      type: API_REMOVE,
    });
  }

  state = {
    phone: "",
    email: "",
    logoapi:false,
    api:false,
    password: "",
    role: "Admin",
    business_name: "",
    showError:false,
    gstin: "",
    logo: "",
    state_code: "",
    address_1: "",
    steps:0,
    address_2: "",
    state: "",
    country: "India",
    pincode: "",
    account_number: "",
    ifsc: "",
    account_holder_name: "",
  };
  handleInputChange=this.handleInputChange.bind(this);
  handleLogo=this.handleLogo.bind(this);
  handleSubmit=this.handleSubmit.bind(this);
  handleLogo(e){
    const formData=new FormData();
    formData.append('image',e.target.files[0]);
    this.setState({logoapi:true});
    this.props.dispatch(CustomerLogo(formData)).then(data=>{this.setState({logo:data.data.data,logoapi:false})}).catch(data=>this.setState({logoapi:false}));
 }
 handleSubmit(e){
  this.setState({api:true});
  document.getElementById('root').style.pointerEvents = 'none';
  this.props.dispatch(addCustomer(this.state)).then(data=>{this.setState({api:false})}).catch(data=>{this.setState({api:false});document.getElementById('root').style.pointerEvents = 'auto';});
}
alert=function(status,message){
  switch(status){
  case 0:{return <>{!this.props.Validation_Error?<Alert style={{justifyContent:'center',borderLeft: '4px solid red',borderRight: '4px solid red'}} severity="error">{typeof message==="string"?message:"Failed"}</Alert>:this.props.Validation_Error.map(data=><Rlert alert={4} message={data.msg}></Rlert>)}</>}
  case 1:{return <Alert style={{justifyContent:'center',borderLeft: '4px solid green',borderRight: '4px solid green'}} severity="success">{message}</Alert>}
 default:{return false}
}
}
 handleInputChange(e){
   e.preventDefault();
   this.setState({[e.target.name]:e.target.value})
 }
  getStepContent(step) {
    let { classes } = this.props;
    switch (step) {
    case 0:
    return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
      <h5>Customer Personal Details</h5>
      </Grid>
       <Grid item md={6} xs={12}>
          <TextField name="email"
          error={this.state.showError&&!this.state.email}
          helperText={this.state.showError&&!this.state.email?"Required":false} style={{width:"90%"}} value={this.state.email} onChange={this.handleInputChange} label="Email"></TextField>
       </Grid>
       <Grid item md={6} xs={12}>
             <TextField id="standard-adornment-password" value={this.state.password} label="Password"  error={this.state.showError&&!this.state.password}
          helperText={this.state.showError&&!this.state.password?"Required":false} type='password' name="password" onChange={this.handleInputChange} />
       </Grid>
       <Grid item md={6} xs={12}>
          <FormControl style={{width:"90%"}}>
             <InputLabel htmlFor="standard-adornment-password">Phone</InputLabel>
             <Input id="standard-adornment-password" value={this.state.phone} type='number' name="phone" onChange={this.handleInputChange} />
          </FormControl>
       </Grid>
       <Grid item md={6} xs={12}></Grid>
       {/* <Grid item md={6} xs={12}>
          <FormControl style={{width:"90%"}} className={classes.formControl}>
             <InputLabel htmlFor="age-native-simple">Role</InputLabel>
             <Input id="standard-adornment-password" value={"Admin"} type='text'/>
          </FormControl>
       </Grid> */}
       <Grid item md={6} xs={12}>
          <TextField name="address_1" style={{width:"90%"}} onChange={this.handleInputChange} value={this.state.address_1} label="Address 1"></TextField>
       </Grid>
       <Grid item md={6} xs={12}>
          <TextField name="address_2" style={{width:"90%"}} onChange={this.handleInputChange} value={this.state.address_2} label="Address 2"></TextField>
       </Grid>
       <Grid item md={6} xs={12}>
          <FormControl style={{width:"90%"}}>
             <InputLabel htmlFor="age-native-simple">Country</InputLabel>
             <Select native onChange={this.handleInputChange} value={this.state.country} inputProps={{
                   name: 'country',
                   id: 'age-native-simple',
                   }}>
                {countries.map((country) => (
            <option
              value={country.countryName}
              key={country.countryShortCode}
            >
              {country.countryName}
            </option>
          ))}
             </Select>
          </FormControl>
       </Grid>
       <Grid item  md={6} xs={12}>
          <FormControl style={{width:"90%"}} >
             <InputLabel style={{color:this.state.showError&&!this.state.state?"red":false}} htmlFor="age-native-simple">State</InputLabel>
             <Select error={this.state.showError&&!this.state.state}
           native  onChange={this.handleInputChange} value={this.state.state} inputProps={{
                   name: 'state',
                   id: 'age-native-simple',
                   }}>
                     <option></option>
                {this.state.country
            ? countries
                .find(({ countryName }) => countryName === this.state.country)
                .regions.map((region) => (
                  <option value={region.name} key={region.shortCode}>
                    {region.name}
                  </option>
                ))
            : []}
             </Select>
             <FormHelperText style={{color:"red"}}>{this.state.showError&&!this.state.state?"Required":false}</FormHelperText>
          </FormControl>
       </Grid>
       <Grid item md={6} xs={12}>
          <FormControl style={{width:"90%"}}>
             <InputLabel htmlFor="standard-adornment-password">Pin Code</InputLabel>
             <Input id="standard-adornment-password" value={this.state.pincode} type='number' name="pincode" onChange={this.handleInputChange} />
          </FormControl>
       </Grid>
    </Grid>
    );
    case 1:
    return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
      <h5>Customer bank details</h5>
      </Grid>
       <Grid item md={6} xs={12}>
          <FormControl style={{width:'90%'}}>
             <InputLabel htmlFor="standard-adornment-password">Account Number</InputLabel>
             <Input id="standard-adornment-password" value={this.state.account_number} type='number' name="account_number" onChange={this.handleInputChange} />
          </FormControl>
       </Grid>
       <Grid item md={6} xs={12}>
          <FormControl style={{width:'90%'}}>
             <InputLabel htmlFor="standard-adornment-password">IFSC</InputLabel>
             <Input id="standard-adornment-password" type='text' value={this.state.ifsc} name="ifsc" onChange={this.handleInputChange} />
          </FormControl>
       </Grid>
       <Grid item md={6} xs={12}>
          <TextField name="account_holder_name" style={{width:'90%'}} onChange={this.handleInputChange} value={this.state.account_holder_name} label="Account Holder Name">
          </TextField>
       </Grid>
    </Grid>
    );
    case 2:
    return (<Grid container spacing={3}>
      <Grid item md={12} xs={12}>
       <h5>Customer company details</h5>
       </Grid>
       <Grid item md={6} xs={12}>
          <TextField style={{width:'90%'}} onChange={this.handleInputChange} value={this.state.business_name} name="business_name" label="Business Name"></TextField>
       </Grid>
       <Grid item md={6} xs={12}>
          <TextField style={{width:'90%'}} name="gstin" onChange={this.handleInputChange} value={this.state.gstin} label="GSTIN"></TextField>
       </Grid>
       <Grid item md={12} xs={12}>
          <TextField style={{width:'90%'}} name="state_code" onChange={this.handleInputChange} value={this.state.state_code} label="State Code"></TextField>
       </Grid>
       <Grid item md={6} xs={6}>
          <SimpleCard title="Logo">
       {this.state.logo?<img src={this.state.logo}></img>:<Image></Image>}
       </SimpleCard>
       </Grid>
       <Grid item md={6} xs={6}>
       <div className="upload-form">
        {/* <SimpleCard title="Logo"> */}
            {this.state.logoapi?<><Button disabled={true} variant="contained" color="primary">Uploading</Button>  {this.state.logoapi?<CircularProgress
                            size={24}
                            className={classes.buttonProg}
                      />:false}</>:<><div className="flex flex-wrap"><label htmlFor="upload-single-file">
              <Fab
                className="capitalize"
                color="primary"
                component="span"
                variant="extended"
              >
                <div className="flex flex-middle">
                  <Icon className="pr-8">cloud_upload</Icon>
                  <span>Logo</span>
                </div>
              </Fab>
              </label>
              </div>
              <input
              className="display-none"
              onChange={this.handleLogo}
              id="upload-single-file"
              type="file"
            /></>}
        {/* </SimpleCard> */}
      </div>
       </Grid>
    </Grid>);
    default:
    return "";
    }
    }

  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleFormSubmit = event => {};
  render() {
    let { classes } = this.props;
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
          {this.state.api?<Loading></Loading>:false}
            <Grid container style={{opacity:this.state.api?0.5:1}}>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center bg-light-gray flex-middle h-100">
                  <img
                    src="../assets/images/logo.png"
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
              {this.alert(this.props.Api_status,this.props.Api)}
                <div className="p-36 h-100">
                  {this.getStepContent(this.state.steps)}
                      <div className="mb-16" style={{paddingTop:"15px"}}>
                        {this.state.steps!==2?<><Button
                        className="capitalize"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                          this.state.steps!==0?this.setState({steps:this.state.steps!==0?this.state.steps-1:this.state.steps}):this.props.history.push("/login")
                         }
                        type="submit"
                      >
                        { this.state.steps!==0?"Back":"Sign In" }
                      </Button>
                      <span className="ml-16 mr-8"></span>
                      <Button
                        type="submit"
                        className="capitalize"
                        variant="contained"
                        color="primary"
                        onClick={() =>{
                          if(this.state.steps===0&&!this.state.email||!this.state.password||!this.state.state)
                          this.setState({showError:true});
                          else
                         this.setState({steps:this.state.steps+1,showError:false})
                        }
                        }
                      >Next
                      </Button></>:<><Button
                        className="capitalize"
                        variant="contained"
                        color="secondary"
                        disabled={this.state.api}
                        onClick={() =>
                          this.setState({steps:this.state.steps!==0?this.state.steps-1:this.state.steps})
                         }
                        type="submit"
                      >
                        Back
                      </Button>
                      <span className="ml-16 mr-8"></span>
                      <Button
                        className="capitalize"
                        variant="contained"
                        onClick={() =>this.handleSubmit()}
                        color="primary"
                        disabled={this.state.logoapi||this.state.api}
                        type="submit"
                      >
                        Sign up
                      </Button>
                      {this.state.logoapi||this.state.api?<CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                      />:false}
                      </>}
                      </div>
                </div> 
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  try {
  return ({
  userId:state.user.userId,
  customer:state.user.customer,
  state:state.user.state,
  account:state.user.account_number,
  account_name:state.user.account_holder_name,
  ifsc:state.user.ifsc,
  user:state.user,
  Api: state.Api.data.message,
  Validation_Error: state.Api.data.data,
  Api_status: state.Api.data.status
  })
  } catch {
  return ({
  userId:state.user.userId,
  customer:state.user.customer,
  account:state.user.account_number,
  account_name:state.user.account_holder_name,
  ifsc:state.user.ifsc,
  state:state.user.state,
  user:state.user,
  Api: '',
  Api_status: ''
  })
  }};

export default withStyles(styles, { withTheme: true })(withRouter(connect(
  mapStateToProps
)(SignUp)));



