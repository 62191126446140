import axios from "axios";

export function apiCall(method, path, data) {
  return new Promise((resolve, reject) => {
    return axios[method.toLowerCase()](
        `https://api.taxary.net/api/${path}`,
        data
      )
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
      if(err.response&&err.response.data.message){ if(err.response.data.message==="We were unable to find a valid token. Your token my have expired."){
        err.response.data.message="We were unable to find a valid token. Your token may have expired.";
        setTimeout(function(){localStorage.clear();window.location.href='/'},4000);
        return reject(err.response.data)}}
        if(typeof err.response === "object")
        return reject({data:{data:err.response.data}} );
       else if (typeof err.message === "string"){
          return reject({data:{data:{status:0,message:err.message}}});
        }
      });
  });
}


