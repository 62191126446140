import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";
// core components
import SnackbarContent from "../Snackbar/SnackbarContent";

import styles from "../../../styles/jss/material-kit-react/views/componentsSections/notificationsStyles";

const useStyles = makeStyles(styles);

export default function SectionNotifications(props) {
  const classes = useStyles();
  const alert = ()=>{switch(props.alert){
    case 1:return <SnackbarContent
      message={
        <span>
          <b>INFO ALERT:</b> {props.message}
        </span>
      }
      close
      color="info"
      icon="info_outline"
    />
    case 2:return <SnackbarContent
      message={
        <span>
          <b>SUCCESS ALERT:</b> {props.message.map(data=><li>{data.msg}</li>)}
        </span>
      }
      close
      color="success"
      icon={Check}
    />
    case 3:return <SnackbarContent
      message={
        <span>
          <b>WARNING ALERT:</b> {props.message}
        </span>
      }
      close
      color="warning"
      icon={Warning}
    />
    case 4:return <SnackbarContent
      message={
        <span>
         {props.message.charAt(0).toUpperCase() + props.message.slice(1)}
        </span>
      }
      close
      color="danger"
      icon="info_outline"
    />
    default:break;
    }};
  return (
    <div className={classes.section} id="notifications">
      <div className={classes.container}>
      {alert()}
      </div>
    </div>
  );
}
