import React from "react";
import { Redirect } from "react-router-dom";

import dashboardRoutes from "./views/dashboard/DashboardRoutes";
import utilitiesRoutes from "./views/utilities/UtilitiesRoutes";
import sessionRoutes from "./views/sessions/SessionRoutes";
import PurchaseInvoiceRoutes from './views/Purchase/PurchaseInvoiceRoutes';
import SalesInvoiceRoutes from './views/Sales/SalesInvoiceRoutes';
import materialRoutes from "./views/material-kit/MaterialRoutes";
//import chartsRoute from "./views/charts/ChartsRoute";
//import dragAndDropRoute from "./views/Drag&Drop/DragAndDropRoute";
import quotationRoutes from "./views/Quotation/QuotationRoutes";
import partiesRoutes from "./views/parties/PartiesRoutes";
import productRoutes from "./views/products/ProductRoutes";
import invoiceRoutes from "./views/invoice/InvoioceRoutes";
import calendarRoutes from "./views/calendar/CalendarRoutes";
//import crudRoute from "./views/CRUD/CrudRoutes";
import inboxRoute from "./views/inbox/InboxRoutes";
import formsRoutes from "./views/forms/FormsRoutes";
//import chatRoutes from "./views/chat-box/ChatRoutes";
import stockRoutes from "./views/stocks/StockRoutes";
import pageLayoutRoutes from "./views/page-layouts/PageLayoutRoutees";
import customerRoutes from "./views/Customer/CustomerRoutes";
import ListRoute from "./views/list/ListRoute";

/*
  Required if you are going to use Evelynn Landing page
  https://themeforest.net/item/react-landing-material-ui-react-saasproduct-landing-page/23847400
*/


const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];
const routes = [
 
  ...stockRoutes,
  ...sessionRoutes,
  ...dashboardRoutes,
  ...materialRoutes,
  ...ListRoute,
  //...chartsRoute,
  
  ...calendarRoutes,
  ...invoiceRoutes,
  //...crudRoute,
  ...PurchaseInvoiceRoutes,
  ...SalesInvoiceRoutes,
  ...inboxRoute,
  ...customerRoutes,
 
  //...chatRoutes,

  ...partiesRoutes,

  ...pageLayoutRoutes,
  ...quotationRoutes,
 
  ...productRoutes,

  ...redirectRoute,
  ...errorRoute,
];  

export default routes;
